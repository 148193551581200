import React from 'react';
import PropTypes from 'prop-types';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
  PinterestShareButton,
  TelegramShareButton,
  EmailShareButton,
  PocketShareButton
} from 'react-share';
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  PinterestIcon,
  PocketIcon
} from "react-share";

import './share.scss';

const Share = ({ socialConfig, socialTags }) => (
  <div className="post-social">
    <EmailShareButton url={socialConfig.config.url} className="button" title="share on Email" subject={socialConfig.config.title} body={socialConfig.config.summary} separator={'... \n'}>
      <span className="icon">
        <EmailIcon size={32} round={true} iconFillColor={"orange"} />
      </span>
    </EmailShareButton>
		<FacebookShareButton url={socialConfig.config.url} className="button" title="share on Facebook" hashtag={`#${socialTags[0]}`} quote={socialConfig.config.title}>
			<span className="icon">
				<FacebookIcon size={32} round={true} />
			</span>
		</FacebookShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} via={socialConfig.author.split('@').join('')} hashtags={socialTags} >
			<span className="icon">
				<TwitterIcon size={32} round={true} />
			</span>
		</TwitterShareButton>
		<LinkedinShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} source={socialConfig.config.url} summary={socialConfig.config.summary}>
			<span className="icon">
				<LinkedinIcon size={32} round={true} />
			</span>
		</LinkedinShareButton>
		<PinterestShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} media={socialConfig.config.media} description={socialConfig.config.summary}>
			<span className="icon">
				<PinterestIcon size={32} round={true} />
			</span>
    </PinterestShareButton>
    <PocketShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} >
			<span className="icon">
				<PocketIcon size={32} round={true} />
			</span>
		</PocketShareButton>
		<WhatsappShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} separator={'... \n'}>
			<span className="icon">
				<WhatsappIcon size={32} round={true} />
			</span>
    </WhatsappShareButton>
    <TelegramShareButton url={socialConfig.config.url} className="button" title={socialConfig.config.title} >
			<span className="icon">
				<TelegramIcon size={32} round={true} />
			</span>
		</TelegramShareButton>
	</div>
);

Share.propTypes = {
	socialConfig: PropTypes.shape({
    author: PropTypes.string.isRequired,
		config: PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
      summary: PropTypes.string.isRequired,
		}),
	}).isRequired,
	socialTags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	socialTags: [],
};

export default Share;