import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout'
import SEO from '../components/seo';
import Share from '../components/share';
import Ebook from './ebooks';

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
        author
      }
    }
    markdownRemark(fields: {slug : { eq: $slug } }) {
      frontmatter {
        title
        date
        tags
        socialTags
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
      fields {
        readingTime {
          text
        }
      }
      excerpt(pruneLength: 300)
    }
  }
`

const Blog = ({ data: { markdownRemark, site } }) => {
  const siteSlug = markdownRemark.frontmatter.title.toLowerCase().replace(/\s/g , '-');
  const summary = markdownRemark.excerpt.replace(/source.+http.+?\s/g, '').replace(/\n/g, ' ').split(' ').slice(0, 25).join(' ');
  return (
    <>
      <div className="social-share">
        <Share
          socialConfig={{
            author: site.siteMetadata.author,
            config: {
              url: `${site.siteMetadata.url}/${siteSlug}`,
              title: markdownRemark.frontmatter.title,
              media: markdownRemark.frontmatter.featuredImage.childImageSharp.fluid.src,
              summary
            },
          }}
          socialTags={markdownRemark.frontmatter.socialTags}
        />
      </div>
      <Layout>
        <div className="article-page">
          <div className="article-content">
            <SEO
              title={markdownRemark.frontmatter.title}
              description={summary}
              keywords={markdownRemark.frontmatter.tags}
              image={markdownRemark.frontmatter.featuredImage.childImageSharp.fluid}
            />
            <h1>{markdownRemark.frontmatter.title}</h1>
            <p className="read-time"><span role="img" aria-label="read-time">&#9203; {markdownRemark.fields.readingTime.text}</span></p>
            <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }}></div>
            <p className="date"><span role="img" aria-label="date">&#128197; {markdownRemark.frontmatter.date}</span></p>
          </div>
          <div className="adverts">
            <div>
              <div className="advert-content">
                <Ebook />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Blog;
